<template>
  <div class="mb-5">
    <maintopview />
    <div>
      <div class="py-32 md-py-5 container">
        <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
          BETBET {{ $t("text_introduce195") }}
        </p>
        <p
          class="md-fs-f-46 fs-f-2-5rem fw-bold text-center mt-5 mx-auto"
          style="max-width: 720px"
        >
          {{ $t("text_introduce196") }}<br />
          {{ $t("text_introduce197") }}
        </p>
      </div>
    </div>
    <div class="my-bg-grey">
      <div class="py-32 md-py-5 container">
        <div class="row">
          <div class="col-lg-6 col-12">
            <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce198") }}
            </p>
            <p class="md-fs-f-46 fs-f-2-5rem fw-bold">
              {{ $t("text_introduce199") }}
            </p>
            <p class="lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce200") }}
            </p>
            <p class="lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce200_1") }}
            </p>
          </div>

          <div class="col-lg-6 col-12 mt-5">
            <img src="@/assets/img/main/site_pic_merc1.png" class="w-100" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="py-32 md-py-5 container">
        <div class="row flex-row-reverse">
          <div class="col-lg-6 col-12">
            <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce201") }}
            </p>
            <p class="md-fs-f-46 fs-f-2-5rem fw-bold">
              {{ $t("text_introduce202") }}
            </p>
            <p class="lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce203") }}
            </p>
            <p class="lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce204") }}
            </p>
          </div>

          <div class="col-lg-6 col-12 mt-5">
            <img src="@/assets/img/main/site_pic_merc2.png" class="w-100" />
          </div>
        </div>
      </div>
    </div>
    <div class="my-bg-grey py-32 md-py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-12">
            <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce205") }}
            </p>
            <p class="md-fs-f-46 fs-f-2-5rem fw-bold">
              {{ $t("text_introduce206") }}
            </p>
            <p
              class="mt-2 mb-4 lg-fs-f-normal fs-f-1-125rem"
              v-html="$t('text_introduce298')"
            ></p>
          </div>

          <div class="col-lg-6 col-12 mt-5">
            <img src="@/assets/img/main/site_pic_merc3.png" class="w-100" />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row flex-row-reverse">
          <div class="col-lg-6 col-12">
            <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce212") }}
            </p>
            <p class="md-fs-f-46 fs-f-2-5rem fw-bold">
              {{ $t("text_introduce213") }}
            </p>
            <p class="mt-2 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce214") }}
            </p>
            <p class="mt-2 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce215") }}
            </p>
            <p class="mt-2 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce216") }}
            </p>
            <p class="mt-2 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce217") }}
            </p>
          </div>

          <div class="col-lg-6 col-12 mt-5">
            <img src="@/assets/img/main/site_pic_merc4.png" class="w-100" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="py-32 md-py-5 container">
        <div class="row">
          <div class="col-lg-6 col-12">
            <p class="mt-5 mb-4 lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce218") }}
            </p>
            <p class="md-fs-f-46 fs-f-2-5rem fw-bold">
              {{ $t("text_introduce219") }}
            </p>
            <p class="lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce220") }}
            </p>
            <p class="lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce221") }}
            </p>
            <p class="lg-fs-f-normal fs-f-1-125rem">
              {{ $t("text_introduce222") }}
            </p>
          </div>

          <div class="col-lg-6 col-12 mt-5">
            <img src="@/assets/img/main/site_pic_merc5.png" class="w-100" />
          </div>
        </div>
      </div>
    </div>

    <div class="my-bg-grey">
      <div class="py-32 md-py-5 container">
        <div class="row">
          <div class="col-12">
            <table
              class="table table-hover"
              style="--bs-table-bg: var(--bg-grey)"
            >
              <thead>
                <tr>
                  <th scope="col">{{ $t("text_introduce223") }}</th>
                  <th scope="col">{{ $t("text_introduce224") }}</th>
                  <th scope="col">{{ $t("text_introduce225") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{{ $t("text_introduce226") }}</th>
                  <td>
                    {{ $t("text_introduce227") }}<br />{{
                      $t("text_introduce228")
                    }}<br />{{ $t("text_introduce229") }}
                  </td>
                  <td>
                    {{ $t("text_introduce230") }}<br />{{
                      $t("text_introduce231")
                    }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{{ $t("text_introduce232") }}</th>
                  <td>
                    {{ $t("text_introduce233") }}<br /><span
                      style="color: #00ba64"
                      >{{ $t("text_introduce234") }}</span
                    >
                  </td>
                  <td>
                    {{ $t("text_introduce235") }}<br /><span
                      style="color: #ff0606"
                      >{{ $t("text_introduce236") }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <th scope="row">{{ $t("text_introduce237") }}</th>
                  <td>{{ $t("text_introduce238") }}</td>
                  <td>{{ $t("text_introduce239") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="px-lg-3">
      <footerview class="mt-5" />
    </div>
  </div>
</template>

<script>
import maintopview from "./main_top.vue";
import footerview from "./footer.vue";
export default {
  name: "merchant",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  components: { maintopview, footerview },
  computed: {},
  methods: {},
  watch: {},
};
</script>
